import { withInquiry } from '@hooks/inquiry.hook';
import { Box, FlexBox } from '@shared/helpers';
import React, { Node, useEffect, useRef } from 'react';

import useStep from '@hooks/step.hook';
import StepPanel from './step-panel';
import StepsFooter from './steps-footer';
import { AZSteps, AZStepsContent } from './styles';

const Steps = ({ children }: { children: Node }) => {
  const ref = useRef();
  const { step } = useStep();
  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [step]);
  return (
    <AZSteps>
      <FlexBox
        flexDirection={['column', 'row']}
        width="100%"
        flexGrow={1}
        overflowY={['auto', 'hidden']}
      >
        <FlexBox
          px={[6, 9]}
          py={[6, 7]}
          width={['100%', 450]}
          flexShrink={0}
          ref={ref}
        >
          <StepPanel />
        </FlexBox>
        <AZStepsContent
          bg="bg.light"
          p={['none', 4]}
          overflowY={['initial', 'auto']}
          pt={[4]}
          px={['none', 4]}
          width={['100%']}
          flexGrow={1}
        >
          <Box bg="#FFF" width="100%">
            <Box bg="#FFF" mb={['none', 4]} p={[40, 100]} width="100%">
              {children}
            </Box>
          </Box>
        </AZStepsContent>
      </FlexBox>
      <StepsFooter />
    </AZSteps>
  );
};

export default withInquiry(Steps);
