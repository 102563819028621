import { useInquiry } from '@hooks/inquiry.hook';
import { Box, Text } from '@shared/helpers';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useRef } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { isWeekend, parse } from 'date-fns';
import * as D from 'date-fns/fp';
import * as R from 'ramda';
import { AZScheduleStep } from './styles';
import TimeslotPicker from './timeslot-picker';
import 'antd/es/date-picker/style/index';

const DatePicker = generatePicker(dateFnsGenerateConfig);

const ScheduleStep = () => {
  const [inquiry, update] = useInquiry();
  const { sm } = useBreakpoint();
  const dateRef = useRef();
  const setDate = (date) => {
    update({
      schedule: {
        date: date ? R.pipe(D.startOfDay, D.setHours(8))(date) : null,
        start: null,
        end: null,
      },
    });
  };
  return (
    <AZScheduleStep>
      <Text.H4 mb={[4]}>
        Please select a date and time for your appointment from our available
        timeslots.
      </Text.H4>

      <Box position="relative" width="100%">
        <DatePicker
          css={{ width: '100%', maxWidth: 300 }}
          format="EEEE, MMM do"
          size="large"
          value={inquiry?.schedule?.date}
          suffix={<CalendarOutlined />}
          onChange={setDate}
          disabledDate={isWeekend}
        />
        {!sm && (
          <input
            type="date"
            ref={dateRef}
            onChange={({ target }) => {
              if (target.value) {
                setDate(parse(target.value, 'yyyy-MM-dd', new Date()));
              } else {
                setDate(null);
              }
            }}
          />
        )}
      </Box>

      <TimeslotPicker />
    </AZScheduleStep>
  );
};

export default ScheduleStep;
