import { educationTypes } from '@/configs/employment.config';
import { useInquiry } from '@hooks/inquiry.hook';
import { Text } from '@shared/helpers';
import { Radio } from 'antd';

import { AZEducationStep } from './styles';

const EducationStep = () => {
  const [inquiry, update] = useInquiry();
  return (
    <AZEducationStep>
      <Text.H4 mb={[4]}>Your highest level of education completed:</Text.H4>
      {educationTypes.map((edu) => (
        <div key={edu}>
          <Radio
            checked={edu === inquiry?.education}
            onChange={() => {
              update({ education: edu });
            }}
          >
            <Text>{edu}</Text>
          </Radio>
        </div>
      ))}
    </AZEducationStep>
  );
};

export default EducationStep;
