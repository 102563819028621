import styled from '@emotion/styled';

export const AZScheduleStep = styled.div`
  [type='date'] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
  }
`;
