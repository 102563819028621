import styled from '@emotion/styled';
import { Box } from '@shared/helpers';

export const AZTimeslotPicker = styled(Box)`
  max-width: 100%;
  width: 600px;
  .ant-btn-sm {
    height: 40px;
  }
`;
