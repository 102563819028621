import styled from '@emotion/styled';
import { Box } from '@shared/helpers';

export const AZCheckboxContainer = styled(Box)`
  .ant-checkbox-wrapper {
    margin-left: 0;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    font-size: 16px;
  }
`;
