import { useState } from 'react';
import { type IService } from '@types/service.types';

const tempServices: [IService] = [
  {
    id: 1,
    name: 'snap',
    label: 'SNAP E&T',
    description:
      'Specialized employment and training services for SNAP recipients',
  },
  {
    id: 2,
    name: 'trade',
    label: 'Trade Adjustment Assistance',
    description: 'For workers laid-off due to foreign outsourcing',
  },
  {
    id: 3,
    name: 'reentry',
    label: 'Reentry',
    description: 'Formerly incarcerated',
  },
  {
    id: 4,
    name: 'general',
    label: 'General Employment',
    question_one: {
      key: 'question_one',
      id: 0,
      label:
        'Have you served in the military or are you a spouse of an eligible veteran? * Eligible veterans and their spouses receive priority of service.',
      description:
        'To learn more about ARIZONA@WORK resources for veterans please visit, <a href="https://arizonaatwork.com/explore-services/veterans" target="_blank">https://arizonaatwork.com/explore-services/veterans</a>.',
      type: 'boolean',
    },
    question_two: {
      key: 'question_two',
      id: 1,
      label:
        'Do you meet one or more of the following criteria? Are you a former service member who:',
      description: '',
      info:
        'This information will help us determine which employment specialist to connect you with. ',
      type: 'checkbox',
      options: [
        {
          key: 'disability',
          text: 'has a disability',
        },
        {
          key: 'homelessness',
          text: 'is experiencing homelessness',
        },
        {
          key: 'separated',
          text:
            'has been separated from the military within the last 12 months',
        },
        {
          key: 'incarcerated',
          text: 'was formerly incarcerated',
        },
        {
          key: 'diploma',
          text: 'is lacking a high school diploma',
        },
        {
          key: 'income',
          text: 'has low or no income',
        },
      ],
      visible: 'question_one',
    },
  },
];

export const useServices = (): [IService] => {
  const [services] = useState(tempServices);
  return services;
};
