import styled from '@emotion/styled';
import { FlexBox } from '@shared/helpers';

export const AZSteps = styled(FlexBox)`
  flex-direction: column;
  width: 100vw;
  > * {
  }
`;

export const AZStepsSider = styled(FlexBox)``;
export const AZStepsContent = styled(FlexBox)``;
export const AZStepsFooter = styled(FlexBox)``;
