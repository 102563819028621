import styled from '@emotion/styled';
import { Box, FlexBox } from '@shared/helpers';

export const AZLanding = styled(FlexBox)`
  max-width: 1200px;
  overflow-y: auto;
  width: 100%;
`;

export const AZLandingImg = styled(Box)`
  img {
    width: 100%;
  }
`;
