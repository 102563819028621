import useAvailability from '@hooks/availability.hook';
import { useInquiry } from '@hooks/inquiry.hook';
import { getLeastBusy } from '@utils/calendar.utils';
import { getTimeSlotArray } from '@utils/date.utils';

import { Col, Row, Spin } from 'antd';

import { useEffect, useRef } from 'react';
import { AZTimeslotPicker } from './styles';
import TimeslotButton from './timeslot-button';

const TimeslotPicker = () => {
  const [inquiry] = useInquiry();
  const { date, start } = inquiry?.schedule || {};
  const group = inquiry?.service?.question_one?.value
    ? 'veteran'
    : inquiry?.service?.name || null;

  const slots = getTimeSlotArray(date);
  const [av, loading] = useAvailability(date, group);
  const ref = useRef();

  useEffect(() => {
    if (date) {
      // eslint-disable-next-line no-unused-expressions
      ref?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [date]);

  return (
    <AZTimeslotPicker mt={[4]} ref={ref}>
      <Spin tip="Checking availability" spinning={loading}>
        <Row gutter={[8, 8]}>
          {slots.map((time) => (
            <Col xs={8} key={time.toISOString()}>
              <TimeslotButton
                time={time}
                date={date}
                availability={av}
                start={start}
                loading={loading}
                employee={getLeastBusy(time)(av)}
              />
            </Col>
          ))}
        </Row>
      </Spin>
    </AZTimeslotPicker>
  );
};

export default TimeslotPicker;
