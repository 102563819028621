import { stepPanelConfig } from '@/configs/step-panel.config';
import { ArrowLeftOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useInquiry } from '@hooks/inquiry.hook';
import useStep from '@hooks/step.hook';
import { navigate } from '@reach/router';
import { createEvent } from '@utils/calendar.utils';
import {
  Button, message, Modal, Space,
} from 'antd';
import * as R from 'ramda';
import { AZStepsFooter } from './styles';

export const phonePattern = /\(\d{3}\) \d{3}-\d{4}/;
export const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const checkDisabled = {
  education: R.complement(R.prop('education')),
  schedule: R.complement(R.path(['schedule', 'start'])),
  contact: R.pipe(
    R.prop('contact'),
    R.allPass([
      R.prop('first'),
      R.prop('last'),
      R.pipe(
        R.propOr('', 'phone'),
        R.ifElse(R.length, R.test(phonePattern), R.T),
      ),
      R.cond([
        [R.prop('noEmail'), R.T],
        [R.T, R.pipe(R.prop('email'), R.test(emailPattern))],
      ]),
    ]),
    R.not,
  ),
};

const StepsFooter = () => {
  const { stepIndex, step } = useStep();
  const [inquiry] = useInquiry();
  const prevStep = stepPanelConfig?.[stepIndex - 1]?.key;
  const nextStep = stepPanelConfig?.[stepIndex + 1]?.key;
  const disabled = checkDisabled[step] && checkDisabled[step](inquiry);

  const submit = async () => {
    await createEvent(inquiry);
    message.success(
      'Application submitted successfully. Check your email for confirmation.',
      4,
    );
    navigate('/success');
  };

  const confirm = () => {
    console.log(inquiry);
    Modal.confirm({
      icon: <ExclamationCircleFilled />,
      title: "Are you sure you're ready to submit?",
      content:
        "Please double check that your information is correct. After submitting, you won't be able to change your appointment.",
      okText: 'Submit',
      cancelText: 'Cancel',
      cancelButtonProps: {
        type: 'link',
      },
      onOk: submit,
    });
  };

  return (
    <AZStepsFooter justifyContent={['center', 'flex-end']} pr={[6]} py={[1, 3]}>
      <Space>
        <Button
          icon={<ArrowLeftOutlined />}
          type="link"
          size="large"
          disabled={stepIndex === 0}
          onClick={() => {
            navigate(`/app/${prevStep}`);
          }}
        >
          Previous Step
        </Button>
        <Button
          type="primary"
          size="large"
          disabled={disabled}
          onClick={() => {
            if (nextStep) {
              navigate(`/app/${nextStep}`);
            } else {
              confirm();
            }
          }}
        >
          {step === 'contact' ? 'Submit' : 'Next Step'}
        </Button>
      </Space>
    </AZStepsFooter>
  );
};

export default StepsFooter;
