import { stepPanelConfig } from '@/configs/step-panel.config';
import { useMatch } from '@reach/router';
import * as R from 'ramda';

const useStep = () => {
  const { step } = useMatch('/app/:step') || {};

  const stepIndex = stepPanelConfig?.findIndex(R.propEq('key', step));
  return { step, stepIndex };
};

export default useStep;
