import Logo from '@shared/logo';
import { Node } from 'react';
import Div100vh from 'react-div-100vh';
import { AZClientContent, AZClientHead, AZClientLayout } from './styles';

type Props = {
  children: Node,
};

const ClientLayout = ({ children }: Props) => (
  <Div100vh>
    <AZClientLayout>
      <AZClientHead px={[3, 9]} py={[2, 3]} bg="primary">
        <Logo />
      </AZClientHead>
      <AZClientContent>{children}</AZClientContent>
    </AZClientLayout>
  </Div100vh>
);

export default ClientLayout;
