const breakpoints = ['576px', '768px'];

const space = [8, 12, 16, 20, 24, 32, 40, 48, 64, 72, 96, 128];

space.none = 0;
const sizes = [];

sizes.header = [32, 40];

export const styledSystem = {
  fontSizes: [],
  colors: {
    primary: '#002C54',
    text: {
      dark: 'rgba(0, 0, 0, 0.85)',
      normal: 'rgba(0, 0, 0, 0.65)',
      light: 'rgba(0, 0, 0, 0.45)',
    },
    bg: {
      light: '#f3f3f3',
    },
    border: '#ddd',
  },
  space,
  breakpoints,
  sizes,
};

const theme = {
  ...styledSystem,
};

export default theme;
