import { message } from 'antd';
import Axios from 'axios';
import * as D from 'date-fns/fp';
import * as R from 'ramda';
import { checkTimeWithinInterval } from './date.utils';

// const GCF_URL = 'https://us-central1-tough-shard-295520.cloudfunctions.net/';
const GCF_URL = '/cloudfunctions';

const gcf = Axios.create({ baseURL: GCF_URL });

export const getAvailability = async (date, group) => {
  try {
    const { data } = await gcf.get('/getAvailability', {
      params: { date, group },
    });
    return data;
  } catch (error) {
    message.error(error.message);
    return null;
  }
};

export const createEvent = async (inquiry) => {
  try {
    const { data } = await gcf.get('/createEvent', {
      params: { data: window.btoa(JSON.stringify(inquiry)) },
    });
    return data;
  } catch (error) {
    message.error(error.message);
    return null;
  }
};

export const formatInterval = R.map((v) => new Date(v));

export const getDuration = R.converge(D.differenceInMinutes, [
  R.prop('start'),
  R.prop('end'),
]);

export const getBusyDuration = R.pipe(
  R.map(R.pipe(formatInterval, getDuration)),
  R.sum,
);

export const getLeastBusy = (time) => R.unless(
  R.isNil,
  R.pipe(
    R.reject(R.any(R.pipe(formatInterval, checkTimeWithinInterval(time)))),
    R.map(getBusyDuration),
    R.toPairs,
    R.sortBy(R.prop(1)),
    R.path([0, 0]),
  ),
);
