import stepOneImg from '@assets/connecting-specialist.png';
import stepTwoImg from '@assets/scheduling-appointment.png';
import stepThreeImg from '@assets/uploading-resume.png';
import stepFourImg from '@assets/finalizing-appointment.png';

export const stepPanelConfig = [
  {
    stepNumber: 1,
    title: 'Connecting you with the right specialist.',
    description: 'Tell us what kind of employment you’re interested in.',
    icon: stepOneImg,
    key: 'service',
  },
  {
    stepNumber: 2,
    title: 'Scheduling the appointment.',
    description:
      'Virtual appointments are easy to schedule, and you’ll receive information on how to connect after signing up.',
    icon: stepTwoImg,
    key: 'schedule',
  },
  {
    stepNumber: 3,
    title: 'Uploading your resume.',
    description:
      'If you already have a resume, please attach a copy of it for your employment specialist to review prior to your appointment.',
    icon: stepThreeImg,
    key: 'resume',
  },
  {
    stepNumber: 4,
    title: 'Education history.',
    description:
      'This information will help your employment specialist identify the best job opportunities for you.',
    icon: stepThreeImg,
    key: 'education',
  },
  {
    stepNumber: 5,
    title: 'Finalizing your appointment.',
    description: 'Let us know how we can contact you.',
    icon: stepFourImg,
    key: 'contact',
  },
];
