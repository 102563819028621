import styled from '@emotion/styled';
import { FlexBox } from '@shared/helpers';

export const AZClientHead = styled(FlexBox)`
  flex-shrink: 0;
`;

export const AZClientLayout = styled(FlexBox)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const AZClientContent = styled(FlexBox)`
  width: 100%;
  flex-grow: 1;
  display: flex;
  overflow-y: hidden;
`;
