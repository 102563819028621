import { Button } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { checkTimeSlot, getInterval, humanTime } from '@utils/date.utils';
import * as R from 'ramda';
import { isFuture, isWeekend } from 'date-fns';
import { useInquiry } from '@hooks/inquiry.hook';

type Props = {
  start: Date,
  time: Date,
  date: Date,
  availability: object,
  loading: Boolean,
  employee: String,
};

const TimeslotButton = ({
  start,
  time,
  date,
  availability,
  loading,
  employee,
}: Props) => {
  const { sm } = useBreakpoint();
  const [, update] = useInquiry();
  const employeeBusy = availability ? checkTimeSlot(time)(availability) : [true];
  const allEmployeesBusy = R.pipe(
    R.values,
    R.ifElse(R.length, R.all(R.identity), R.length),
  )(employeeBusy);

  const disabled = allEmployeesBusy || !isFuture(time) || loading || isWeekend(time) || !date;
  return (
    <Button
      size={sm ? 'large' : 'small'}
      type="primary"
      block
      ghost={!R.equals(start, time)}
      disabled={disabled}
      onClick={() => {
        update({ schedule: { ...getInterval(time), employee } });
      }}
    >
      {humanTime(time)}
    </Button>
  );
};

export default TimeslotButton;
