// import { routerConfig } from '@/configs/router.config';
import theme from '@constants/theme';
import { Router, LocationProvider, Redirect } from '@reach/router';
import { ThemeProvider } from 'emotion-theming';
import React, { Node } from 'react';
import ClientLayout from './client-layout';
import Landing from './landing';
import Steps from './steps';
import ContactStep from './steps/contact-step';
import EducationStep from './steps/education-step';
import ResumeStep from './steps/resume-step';
import ScheduleStep from './steps/schedule-step';
import ServiceStep from './steps/service-step';
import Success from './success';

const LayoutRouterComponent = ({ children }: { children: Node }) => (
  <>{children}</>
);

const App = () => {
  console.log('Hello and welcome. Again.');
  return (
    <LocationProvider>
      <ThemeProvider theme={theme}>
        <ClientLayout>
          <Router component={LayoutRouterComponent}>
            <Landing path="/" />
            <Steps path="/app">
              <Redirect from="/" to="service" />
              <ServiceStep path="service" />
              <ScheduleStep path="schedule" />
              <ResumeStep path="resume" />
              <EducationStep path="education" />
              <ContactStep path="contact" />
            </Steps>
            <Success path="success" />
          </Router>
        </ClientLayout>
      </ThemeProvider>
    </LocationProvider>
  );
};

export default App;
