import { message } from 'antd';
import Axios from 'axios';

const GCF_URL = '/cloudfunctions';
const gcf = Axios.create({ baseURL: GCF_URL });

export const getSignedUrl = async () => {
  try {
    const { data } = await gcf.get('getSignedUrl');
    return data;
  } catch (error) {
    message.error(error.message);
    return null;
  }
};

export const getMediaLink = async (id) => {
  try {
    const { data } = await gcf.get('getMediaLink', { params: { id } });
    return data;
  } catch (error) {
    message.error(error.message);
    return null;
  }
};

export const uploadFile = async ({
  action,
  onProgress,
  onSuccess,
  onError,
  file,
}) => {
  // eslint-disable-next-line no-undef
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', action.url, true);
  xhr.upload.onprogress = ({ loaded, total }) => {
    onProgress({ percent: (loaded / total) * 100 }, file);
  };
  xhr.onload = async () => {
    const { status } = xhr;
    if (status === 200) {
      const link = await getMediaLink(action.id);
      xhr.url = link;
      onSuccess(xhr, file);
    } else {
      onError();
    }
  };

  xhr.onerror = (error) => {
    onError(error);
  };
  xhr.setRequestHeader('Content-Type', file.type);
  xhr.send(file);
};
