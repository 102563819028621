import styled from '@emotion/styled';

export const AZServiceStep = styled.div`
  .ant-radio-wrapper {
    display: flex;
    align-items: flex-start;
    span.ant-radio {
      margin-top: 4px;
    }

    span:not(.ant-radio) {
      white-space: normal;
    }
    margin-bottom: 14px;
  }
  span.ant-checkbox {
    margin-top: 4px;
  }
`;
