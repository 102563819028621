import { Button } from 'antd';
import img from '@assets/landing.png';
import { Box, FlexBox, Text } from '@shared/helpers';
import { navigate } from '@reach/router';
import { AZLanding, AZLandingImg } from './styles';

const Landing = () => {
  const getStarted = () => {
    navigate('/app');
  };
  return (
    <AZLanding mx="auto" py={[6, 11]} flexDirection={['column', 'row-reverse']}>
      <FlexBox
        width={['100%', '50%']}
        pl={['none', '60px']}
        mx={['none', 2]}
        mb={[7, 'none']}
        justifyContent={['center', 'flex-end']}
        flexShrink={0}
      >
        <AZLandingImg maxWidth={[200, 420]} flexShrink={0}>
          <img src={img} alt="Arizona@Work" />
        </AZLandingImg>
      </FlexBox>
      <Box
        width={['100%', '50%']}
        pr={[6, '60px']}
        mx={['none', 2]}
        pl={[6, 'none']}
      >
        <Text.H1 mb={[5, 6]}>
          Schedule a Virtual Appointment With an Employment Specialist
        </Text.H1>
        <Text.H4 mb={[5, 6]}>With new skills, come new opportunities</Text.H4>
        <Text mb={[6, 7]}>
          ARIZONA@WORK can help you obtain training tools and acquire in-demand
          skills through no-cost employment readiness services to help you
          return to work prepared for a new start.
        </Text>
        <Button size="large" type="primary" onClick={getStarted}>
          Get Started
        </Button>
      </Box>
    </AZLanding>
  );
};

export default Landing;
