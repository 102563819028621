import { useInquiry } from '@hooks/inquiry.hook';
import { Box, Text } from '@shared/helpers';
import { type IQuestion } from '@types/service.types';
import { Checkbox, Radio } from 'antd';
import React, { useEffect, useRef } from 'react';
import * as R from 'ramda';
import { AZCheckboxContainer } from './styles';

const AdditionalQuestions = ({ questions }: { questions: [IQuestion] }) => {
  const [inquiry, update] = useInquiry();
  const setQuestion = (key, value) => () => {
    update({ service: { [key]: { value } } });
  };

  const setCheckboxQuestion = (key, idx) => (value) => {
    const service = R.set(
      R.lensPath([key, 'options', idx, 'value']),
      value?.target?.checked,
    )(inquiry?.service);

    update({ service });
  };

  const boxRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      boxRef.current.parentElement.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  }, []);

  return (
    <div ref={boxRef}>
      {questions.map(
        ({
          label, description, info, type, key, options, visible,
        }) => (!visible || inquiry?.service?.[visible]?.value) && (
        <Box mt={[5]} key={key}>
          <Text.H5 mb={[4]}>{label}</Text.H5>
          {type === 'checkbox' && (
          <AZCheckboxContainer mt={[24]}>
            {options.map((option, idx) => {
              const checked = inquiry?.service?.[key]?.options?.[idx]?.value;
              return (
                <Checkbox
                  key={option.key}
                  checked={checked}
                  onChange={setCheckboxQuestion(key, idx)}
                >
                  {option.text}
                </Checkbox>
              );
            })}
          </AZCheckboxContainer>
          )}
          <Text>
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </Text>
          {info && <Text.H5>{info}</Text.H5>}
          {type === 'boolean' && (
          <Box mt={[24]}>
            <Radio
              checked={inquiry?.service?.[key]?.value}
              onChange={setQuestion(key, true)}
            >
              Yes
            </Radio>
            <Radio
              checked={!inquiry?.service?.[key].value}
              onChange={setQuestion(key, false)}
            >
              No
            </Radio>
          </Box>
          )}
        </Box>
        ),
      )}
    </div>
  );
};

export default AdditionalQuestions;
