import { Col, Progress } from 'antd';
import { stepPanelConfig } from '@/configs/step-panel.config';
import { Text, Box } from '@shared/helpers';
import useStep from '@hooks/step.hook';
import { AZStepPanel } from './styles';

const StepPanel = () => {
  const { stepIndex } = useStep();
  const stepProgressText = `Step ${stepIndex + 1} of ${stepPanelConfig.length}`;
  const percentCompleted = ((stepIndex + 1) / stepPanelConfig.length) * 100;
  const step = stepPanelConfig[stepIndex] || {};

  return (
    <AZStepPanel>
      <Col>
        <Box width={[40]} mb={[2, 4]}>
          <img src={step.icon} alt={step.title || 'Image'} />
        </Box>
        <Text.H2 mb={[0, 6]}>{step.title}</Text.H2>
        <Text fontSize={[16, 20]} lineHeight={['24px', '28px']} mb={[5, 7]}>
          {step.description}
        </Text>
        <Col className="progress-div">
          <Text>{stepProgressText}</Text>
          <Progress percent={percentCompleted} />
        </Col>
      </Col>
    </AZStepPanel>
  );
};

export default StepPanel;
