import styled from '@emotion/styled';
import { Button } from 'antd';
import { Box, FlexBox } from '@shared/helpers';

export const AZSuccess = styled(FlexBox)`
  max-width: 1200px;
  overflow-y: auto;
  width: 100%;
  a.ant-btn {
    padding-top: 0;
  }
`;

export const AZSuccessButton = styled(Button)`
  margin-right: 24px;
`;

export const AZConfirmation = styled(FlexBox)`
  width: 100%;
`;

export const AZSuccessImg = styled(Box)`
  img {
    width: 100%;
  }
`;
