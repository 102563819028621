import logo from '@assets/arizona-des-logo.png';
import { navigate } from '@reach/router';
import { AZLogo } from './styles';

const Logo = () => (
  <AZLogo
    height={['header.0', 'header.1']}
    onClick={() => {
      navigate('/');
    }}
  >
    <img src={logo} alt="Arizona DES Logo" />
  </AZLogo>
);

export default Logo;
