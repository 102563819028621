export const employmentTypes = [
  'Full-time',
  'Part-time',
  'Temporary',
  'Seasonal',
  'Other',
];

export const educationTypes = [
  'Less than high school/GED',
  'High school/GED',
  'Technical/trade school',
  'Some college',
  'Associate’s degree',
  'Bachelor’s degree',
  'Master’s degree',
];
