/* eslint-disable react/prop-types */
import {
  space,
  flexbox,
  color,
  layout,
  border,
  shadow,
  position,
  background,
  compose,
  typography,
} from 'styled-system';
import styled from '@emotion/styled';

export const Box = styled.div`
  box-sizing: border-box;
  min-width: 0;
  ${compose(space, layout, color, border, shadow, position, background)}
`;

export const FlexBox = styled(Box)`
  display: flex;
  ${flexbox};
`;

export const CenterBox = styled(FlexBox)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  ${compose(typography, color, space)}
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
`;

Text.H1 = ({ children, ...props }) => (
  <Text
    fontSize={[38, 46]}
    lineHeight={['46px', '52px']}
    color="text.dark"
    fontWeight={600}
    {...props}
  >
    {children}
  </Text>
);

Text.H2 = ({ children, ...props }) => (
  <Text
    fontSize={[30, 38]}
    lineHeight={['38px', '46px']}
    color="text.dark"
    fontWeight={600}
    {...props}
  >
    {children}
  </Text>
);

Text.H4 = ({ children, ...props }) => (
  <Text
    fontSize={[20, 24]}
    lineHeight={['28px', '32px']}
    color="text.dark"
    fontWeight={600}
    {...props}
  >
    {children}
  </Text>
);

Text.H5 = ({ children, ...props }) => (
  <Text
    fontSize={[20]}
    lineHeight={['28px']}
    color="text.dark"
    fontWeight={600}
    {...props}
  >
    {children}
  </Text>
);
