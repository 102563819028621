import * as R from 'ramda';
import {
  addMinutes,
  startOfDay,
  setHours,
  format,
  isWithinInterval,
} from 'date-fns/fp';

export const dateFormat = 'MM/dd/yyyy';
export const timeDisplayFormat = 'h:mm a';
export const appointmentDateFormat = 'EEEE, MMMM do yyyy \n ';
export const appointmentTimeFormat = 'h:mma';

const formatDate = format(dateFormat);
const formatTime = format(timeDisplayFormat);

export const humanDate = formatDate;

export const humanTime = formatTime;

export const addMinutesToLast = R.converge(R.append, [
  R.pipe(R.last, addMinutes(30)),
  R.identity,
]);

export const getTimeSlotArray = R.pipe(
  R.when(R.isNil, R.always(new Date())),
  startOfDay,
  setHours(9),
  R.of,
  R.until(R.pipe(R.length, R.lte(18)), addMinutesToLast),
);

export const getInterval = R.applySpec({
  start: R.identity,
  end: addMinutes(30),
});

export const checkTimeWithinInterval = (time) => (interval) => isWithinInterval(interval)(time)
  && isWithinInterval(interval)(addMinutes(30)(time));

export const checkTimeSlot = (time) => R.map(
  R.pipe(
    R.map(R.map((d) => new Date(d))),
    R.any(checkTimeWithinInterval(time)),
  ),
);
