import { Button, Image } from 'antd';
import { Box, FlexBox, Text } from '@shared/helpers';
import img from '@assets/success.png';
import facebookImg from '@assets/facebook.png';
import twitterImg from '@assets/twitter.png';
import {
  AZSuccess,
  AZSuccessButton,
  AZConfirmation,
  AZSuccessImg,
} from './styles';

const arizonaJobConnectionURL = 'https://www.azjobconnection.gov/ada/r/';
const arizonaAtWorkURL = 'http://www.arizonaatwork.com/job-market-trends/demand-jobs';
// const azCommerceURL = 'http://www.azcommerce.com/oeo/labor-market';
const azFacebookURL = 'https://www.facebook.com/ARIZONAatWORK/';
const azTwitterURL = 'https://twitter.com/ArizonaatWork';

const Success = () => (
  <AZSuccess mx="auto" flexDirection="column">
    <AZConfirmation py={[5, 72]} flexDirection={['column', 'row-reverse']}>
      <FlexBox
        width={['100%', '50%']}
        pr={['none', '60px']}
        mx={['none', 2]}
        mb={[7, 'none']}
        justifyContent={['center', 'flex-end']}
      >
        <AZSuccessImg maxWidth={[233, 372]}>
          <img src={img} alt="Arizona Confirmation Success" />
        </AZSuccessImg>
      </FlexBox>
      <Box
        width={['100%', '50%']}
        pr={[5, 'none']}
        pl={[5, '60px']}
        mx={['none', 2]}
      >
        <Text.H1>Thank you.</Text.H1>
        <Text.H1 mb={[5, 6]}>Your appointment has been scheduled.</Text.H1>
        <Text.H4 mb={[5, 6]}>
          You will receive a confirmation email or a phone call with next steps.
        </Text.H4>
      </Box>
    </AZConfirmation>
    <FlexBox
      width="100%"
      justifyContent={['center', 'space-between']}
      flexDirection={['column', 'row']}
    >
      <Box
        width={['100%', '50%']}
        pr={[5, 'none']}
        pl={[5, '60px']}
        mx={['none', 2]}
      >
        <Text.H4 mb={16}>More Ways to Search</Text.H4>
        <Text mb={[5, 24]}>
          To prepare for your scheduled appointment, please create an account on
          Arizona&apos;s largest jobs database, the Arizona Job Connection (AJC)
          at&nbsp;
          <a href={arizonaJobConnectionURL} target="_blank noopener noreferrer">
            www.AzJobConnection.gov
          </a>
          . On AJC, you can create or upload a resume, search for jobs with
          employers who have immediate openings, learn about no-cost upskilling
          and training opportunities, and have your skills matched with hiring
          employers.
        </Text>
        <Button
          size="large"
          type="primary"
          href={arizonaJobConnectionURL}
          target="_blank"
        >
          Arizona Job Connection
        </Button>
        {/* <Text mb={[5, 24]} mt={[5, 56]}>
          Job seekers are also encouraged to review Arizona’s in-demand
          occupations and industries on the following sites:
        </Text> */}
        {/* <FlexBox width="100%" flexDirection="row" mb={[50, 0]}>
          <AZSuccessButton
            size="large"
            type="primary"
            href={arizonaAtWorkURL}
            target="_blank"
          >
            Arizona At Work
          </AZSuccessButton>
          <Button
            size="large"
            type="primary"
            href={azCommerceURL}
            target="_blank"
          >
            AZ Commerce
          </Button>
        </FlexBox> */}
      </Box>
      <Box
        width={['100%', '50%']}
        pr={[5, 'none']}
        pl={[5, '60px']}
        mx={['none', 2]}
        mb={[10, 'none']}
      >
        <Text.H4 mb={16}>Stay Connected</Text.H4>
        <Text mb={[5, 24]}>
          Kickstart your career journey by exploring the employment programs
          ARIZONA@WORK has available for job seekers and join us for upcoming
          virtual job fairs and employment workshops. Follow us on&nbsp;
          <a href={azFacebookURL} target="_blank noopener noreferrer">
            Facebook
          </a>
          &nbsp;and&nbsp;
          <a href={azTwitterURL} target="_blank noopener noreferrer">
            Twitter
          </a>
          &nbsp;for additional information.
        </Text>
        <Box mb={2}>
          <Button
            size="large"
            type="primary"
            href={arizonaAtWorkURL}
            target="_blank"
          >
            ARIZONA@WORK
          </Button>
        </Box>
        <AZSuccessButton
          type="link"
          href={azFacebookURL}
          target="_blank"
          icon={(
            <Image
              width={40}
              src={facebookImg}
              alt="Go to ARIZONA@WORK Facebook"
              preview={false}
            />
          )}
        />
        <Button
          type="link"
          href={azTwitterURL}
          target="_blank"
          icon={(
            <Image
              width={45}
              src={twitterImg}
              alt="Go to ARIZONA@WORK Twitter"
              preview={false}
            />
          )}
        />
      </Box>
    </FlexBox>
  </AZSuccess>
);

export default Success;
