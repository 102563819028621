import { Upload, message } from 'antd';
import { ContainerOutlined } from '@ant-design/icons';
import { Box, Text } from '@shared/helpers';
import { useEffect, useState } from 'react';
import { getSignedUrl, uploadFile } from '@utils/gcs.utils';
import { useInquiry } from '@hooks/inquiry.hook';
import { AZResumeStep } from './styles';

const { Dragger } = Upload;
const ResumeStep = () => {
  const [url, setUrl] = useState();
  const [, update] = useInquiry();
  useEffect(() => {
    const getUrl = async () => {
      setUrl(await getSignedUrl());
    };
    getUrl();
  }, []);

  const onChange = ({ fileList, event }) => {
    if (!event) {
      const attachments = fileList?.map(({ response, name }) => ({
        uri: response?.url,
        filename: name,
      }));
      update({ attachments });
    }
  };

  const props = {
    name: 'file',
    action: url,
    accept: 'image/*,.pdf,.zip,.doc,.docx',
    listType: 'picture',
    method: 'put',
    customRequest: uploadFile,
    onPreview: () => {},
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    disabled: !url,
  };
  return (
    <AZResumeStep>
      <Text.H4 mb={[6, '54px']}>
        If you already have a resume, please attach a copy for your employment
        specialist to review prior to your appointment.
      </Text.H4>

      <Dragger {...props} onChange={onChange}>
        <Box py={[4]} px={[3]}>
          <Box color="primary" mb={[3]}>
            <ContainerOutlined style={{ fontSize: 48 }} />
          </Box>
          <Text color="text.dark">Attach Resume Here</Text>
          <Text color="text.light" fontSize={[14]} mt={['5px']}>
            File Types Accepted：.zip .doc .docx .pdf .jpg
          </Text>
        </Box>
      </Dragger>
    </AZResumeStep>
  );
};

export default ResumeStep;
