import { employmentTypes } from '@/configs/employment.config';
import { useInquiry } from '@hooks/inquiry.hook';
import { Box, Text } from '@shared/helpers';
import { iOS } from '@utils/responsive.utils';
import { Checkbox, Input, Select } from 'antd';
import MaskedInput from 'antd-mask-input';
import { AZContactStep } from './styles';

const formConfig = [
  {
    label: 'First name',
    key: 'first',
  },
  {
    label: 'Last name',
    key: 'last',
  },
  {
    label: 'Phone',
    mask: '(111) 111-1111',
    key: 'phone',
  },
  {
    label: 'Email',
    key: 'email',
    disabled: 'noEmail',
  },
];

const ContactStep = () => {
  const [inquiry, update] = useInquiry();
  const noEmail = inquiry?.contact?.noEmail;
  return (
    <AZContactStep>
      <Text.H4>Enter your contact information below:</Text.H4>
      <Box width="100%" maxWidth={300} mb={['4px']}>
        {formConfig.map(({
          label, key, mask, disabled,
        }) => {
          const Component = mask ? MaskedInput : Input;
          return (
            <Box key={key} mt={[4]}>
              <Component
                mask={mask}
                placeholder={label}
                size="large"
                value={inquiry?.contact?.[key]}
                onChange={({ target }) => {
                  update({ contact: { [key]: target.value } });
                }}
                disabled={disabled && inquiry?.contact?.[disabled]}
                onFocus={({ target }) => {
                  setTimeout(() => {
                    if (!iOS()) {
                      target.scrollIntoView({ behavior: 'smooth' });
                    }
                  }, 200);
                }}
              />
            </Box>
          );
        })}
      </Box>
      <Checkbox
        checked={inquiry?.contact?.noEmail}
        onChange={() => {
          update({ contact: { noEmail: !noEmail } });
        }}
      >
        I currently don&apos;t have an email, please contact me by phone
      </Checkbox>
      <Box mt={[5]}>
        <Text.H4>
          What type of employment are you interested in? (Optional)
        </Text.H4>
        <Box mt={[4]} maxWidth={300}>
          <Select
            size="large"
            placeholder="Select type"
            value={inquiry?.contact?.type}
            allowClear
            onChange={(type) => {
              update({ contact: { type } });
            }}
          >
            {employmentTypes.map((v) => (
              <Select.Option value={v} key={v}>{v}</Select.Option>
            ))}
          </Select>
        </Box>
      </Box>
    </AZContactStep>
  );
};

export default ContactStep;
