import { createContext, useContext, useState } from 'react';
import * as R from 'ramda';

const InquiryContext = createContext();

export const InquiryProvider = InquiryContext.Provider;
export const InquiryConsumer = InquiryContext.Consumer;
export const useInquiry = () => useContext(InquiryContext);

const state = {
  service: {
    id: 1,
    name: 'snap',
    label: 'SNAP E&T',
    description:
      'Specialized employment and training services for SNAP recipients',
  },
  schedule: {
    date: null,
  },
  contact: {},
  attachments: [],
};

export const withInquiry = (WrappedComponent) => {
  const Inquiry = (props) => {
    const [value, setValue] = useState(state);
    const update = (newValues) => {
      setValue(R.mergeDeepRight(value, newValues));
    };
    return (
      <InquiryProvider value={[value, update]}>
        <WrappedComponent {...props} />
      </InquiryProvider>
    );
  };
  Inquiry.getInitialProps = (ctx) => {
    if (WrappedComponent.getInitialProps) {
      WrappedComponent.getInitialProps(ctx);
    }
  };
  return Inquiry;
};

export default InquiryContext;
