import styled from '@emotion/styled';

export const AZEducationStep = styled.div`
  .ant-select {
    width: 100%;
  }
  .ant-radio-wrapper {
    display: flex;
    align-items: flex-start;
    span.ant-radio {
      margin-top: 4px;
    }

    span:not(.ant-radio) {
      white-space: normal;
    }
    margin-bottom: 14px;
  }
`;
