import { getAvailability } from '@utils/calendar.utils';
import { useEffect, useState } from 'react';

const useAvailability = (date: Date, group) => {
  const [availability, setAvailability] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setAvailability(await getAvailability(date, group));
      setLoading(false);
    };
    if (date) {
      fetchData();
    }
  }, [date, group]);
  return [availability, loading];
};

export default useAvailability;
