import { Text } from '@shared/helpers';
import { useServices } from '@hooks/services.hook';
import { Radio } from 'antd';
import React from 'react';
import { useInquiry } from '@hooks/inquiry.hook';
import { AZServiceStep } from './styles';
import AdditionalQuestions from './additional-questions';

const ServiceStep = () => {
  const services = useServices();
  const [inquiry, update] = useInquiry();

  const setService = (service) => () => {
    update({ service });
  };

  return (
    <AZServiceStep>
      <Text.H4 mb={[4]}>
        Please select the type of employment services appointment you’re
        seeking: *
      </Text.H4>
      {services.map((service) => (
        <div key={service.id}>
          <Radio
            checked={service.name === inquiry?.service?.name}
            onChange={setService(service)}
          >
            <Text>
              {service.label}
              {service.description && ` (${service.description})`}
            </Text>
          </Radio>
          {service.name === inquiry?.service?.name && service.question_one && (
            <AdditionalQuestions
              questions={[service.question_one, service.question_two]}
            />
          )}
        </div>
      ))}
    </AZServiceStep>
  );
};

export default ServiceStep;
